import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format, parseISO } from 'date-fns';
import './Pages.css';

function BlogPost() {
    const params = useParams();
    const [state, setState] = useState({dataStr: "", author: "", content: [], title: ""})

    useEffect(() => {
        fetch("/posts/readPost/" + params.postid).then((res) =>
            res.json().then((data) => {
                let actualData = data[params.postid];
                    setState({datestr: format(parseISO(actualData[0]), 'MMMM dd, yyyy'), author: actualData[2], content: actualData[3].split("\n"), title: actualData[1]});
              })
        );
    }, []);

    return (
        <div  className="Pages-commonStyle">
            <h1>{state.title} </h1>
            <h2> {state.datestr} </h2>
            <ul className="Pages-nobullets">
            {state.content.map(function(line, index){
                    return <li key={ index } style={{ marginBottom:"10px"}}>{line}</li>;
                  })}
             </ul>
        </div>);
};
 
export default BlogPost;