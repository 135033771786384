import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import About from './pages/about';
import Learnings from './pages/learnings';
import Blog from './pages/blog';
import BlogPost from './pages/blogpost';

function App() {
return (
	<Router>
		<Navbar />
			<Routes>
				<Route path='/' exact element={<Home/>} />
				<Route path='/about' element={<About/>} />
				<Route path='/learnings' element={<Learnings/>} />
				<Route path='/blog' element={<Blog/>} />
				<Route path='/blog/posts/:postid' element={<BlogPost/>} />
			</Routes>
	</Router>
);
}

export default App;
