import React from 'react';
  
const About = () => {
  return (
    <div className="Pages-commonStyle">
    <h1> About Me </h1>
    <p>
        Hi, my name is Ram Balasubramanian. This website is 10 years in the making (in that I postponed for 10 years!). I have no goal in mind in setting it up besides “putting yourself out there” which seems to be the trend these days. But seriously, I do hope to share my experiences, and in doing so reflect on them more deeply and keep growing.
      </p>
    </div>
  );
};
  
export default About;
