import React from 'react';
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
} from './NavbarElements';

const Navbar = () => {
return (
	<>
	<Nav>
		<Bars />
		<NavMenu>
        <NavLink to='/'>
			home
		</NavLink>
		<NavLink to='/about'>
			about
		</NavLink>
		<NavLink to='/blog'>
			blog
		</NavLink>
        </NavMenu>
	</Nav>
	</>
);
};

export default Navbar;
