import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import './Pages.css';

function Learnings() {
const [data, setData] = useState([]);

useEffect(() => {
  fetch("posts/readLessons").then((res) =>
      res.json().then((data) => {
          setData(data);
        })
  );
}, []);

return (
  <div className="Pages-commonStyle">
    <h1>Lessons</h1>
      <ul className="Pages-nobullets">
        {Object.keys(data).map((key) => (
          <li key={key}>{format(parseISO(key), 'MMMM dd, yyyy')}&nbsp;&nbsp;<b> <Link className="Pages-nounderlinelink" to={{ pathname:"/blog/posts/"+key}} params = {{ postid: key}}> {data[key][1]} </Link> </b></li>
        ))}
     </ul>
  </div>
);
};
  
export default Learnings;