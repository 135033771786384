import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import './Pages.css';
  
function Blog() {

  const [token, setToken] = useState(null);
  const [showPrevious, setShowPrevious] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [prevToken, setPrevToken] = useState(null);
  const [data, setData] = useState([]);
  const [remainingItems, setRemainingItems] = useState(0);

  useEffect(() => {
    let urlPath = "/posts/readPaginatedPosts" + (token ? "/" + token: "");
    fetch(urlPath).then((res) =>
        res.json().then((d) => {
            setData(d);
            setNextToken(Object.keys(d).filter((key) => (key !== "remaining_items" && key !== "prev_token" && key !== "show_previous")).slice(-1).pop());
            setShowPrevious(d["show_previous"]);
            setRemainingItems(d["remaining_items"]);
            setPrevToken(d["prev_token"]);
          }));
}, [token]);

  return (
    <div className="Pages-commonStyle">
      <h1>Posts</h1>
        <ul className="Pages-nobullets">
          {Object.keys(data).filter((key) => (key !== "remaining_items" && key !== "prev_token" && key !== "show_previous")).map((key) => (
            <li key={key} style={{ marginBottom:"15px"}}>{format(parseISO(key), 'MMMM dd, yyyy')}&nbsp;&nbsp;<b> <Link className="Pages-nounderlinelink" to={{ pathname:"/blog/posts/"+key}} params = {{ postid: key}}> {data[key][1]} </Link> </b></li>
          ))}
       </ul>
       <footer> <p> {showPrevious? <a href="javascript:void(0)" onClick={() => setToken(prevToken)}>prev</a> : ""}&nbsp;{remainingItems> 0 ? <a href="javascript:void(0)" onClick={() => setToken(nextToken)}>next</a>: ""}</p></footer>
    </div>
  );
};
  
export default Blog;