import React from 'react';
  
const Home = () => {
  return (
    <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'Center',
      height: '100vh',
      wordSpacing: '3px',
      fontSize: '20px',
      paddingLeft: '10%',
      paddingRight: '10%'
    }}
    >
      <br/>
      <img src="images/AvatarMaker.png"  alt="logo" className="App-newlogo"/>
      <p> <b> Ram GB </b></p>
      <p> Software Engineer </p>
      <p> Calvin and Hobbes Enthusiast </p>
      <div style={{
        display: 'flex',
        alignItems: 'Center',
        height: '100vh',
        gap: '25px'
      }}>
      <a href="https://github.com/ramgb">
         <img src="images/github.png" alt="github-logo" className="App-linklogo"/>
      </a>
      <a href="https://www.linkedin.com/in/ramgb/">
        <img src="images/linkedin.png" alt="linkedin-logo" className="App-linklogo"/>
      </a>
      </div>
    </div>
  );
};
  
export default Home;